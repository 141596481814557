import {useEffect, useState} from 'react';
import {Container, Stack, Paper, Box} from '@mui/material';
import {get} from './api/all';
import IndexItem from './IndexItem';
import IndexAdd from './IndexAdd';
import Message from './Message';
import Loader from './Loader';

const Index = () => {
    const [rows, setRows] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            setLoader(true);
            get().then(data => {
                setRows(data['results']); 
                setLoader(false);
            })
        }
    }, [loaded, loader]);
    return (<Container maxWidth="xs">
        <Message />
        <Loader loader={loader} />
        {loaded && !loader && rows.length === 0 && (<Paper sx={{padding:2}}><Box align="center">No hay usuarios para mostrar</Box></Paper>)}
        <IndexAdd />
        <Stack spacing={2}>
            {rows.map((row, index) => (<IndexItem key={"item-"+index} uid={row.uid} email={row.email}  />))}
        </Stack>
        </Container>)
}
export default Index;
