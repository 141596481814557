const endpoint_index = 'https://api.coupon.cl/campaigns/';
const endpoint = 'https://api.coupon.cl/campaign/';

export const getAll = () => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoint, options).then(data => data.json());
}

export const get = (page=null) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    if(page === null)
        return fetch(endpoint_index, options).then(data => data.json());
    return fetch(endpoint_index + '?page='+page, options).then(data => data.json());
}

export const patch = (event, uid, file) => {
    const data = new FormData(event.currentTarget);
    data.append("uid", uid)
    data.append('file', file);
    const options = {
        method: "PATCH",
        credentials: 'include',
        body: data
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const post = (uid) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const put = (event, file) => {
    const data = new FormData(event.currentTarget);
    data.append('file', file);
    const options = {
        method: "PUT",
        credentials: 'include',
        body: data
    };
    return fetch(endpoint, options).then((response) => response.json());
}

export const remove = (uid) => {
    const options = {
        method: "DELETE",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({uid: uid}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}
