import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Container, Paper, Stack, Typography, Link} from '@mui/material';
import {post} from './api/all';

import Loader from './Loader';

const QR = () => {
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [qrData, setQRData] = useState(null);
    const [url, setURL] = useState(null);
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            setLoader(true);
            post(uid).then(data => {
                setQRData(data.qr_data);
                setURL(data.url);
                setLoader(false);
            });
        }
    }, [loaded, uid]);

    return (<Container maxWidth="xs">
        <Loader loader={loader} />
        {loaded && !loader && (<Paper sx={{p:4}} align="center">
            <Stack>
                {qrData !== null && (<img src={qrData} alt="QR" />)}
                <Typography sx={{mt:4}}>Este es un enlace a la campaña</Typography>
                <Link href={url}>{url}</Link>
            </Stack>
        </Paper>)}
        </Container>);
}

export default QR;
