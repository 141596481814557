import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Container, Box} from '@mui/material';

const Loader = (props) => {
    const loader = props.loader;
    return (<>
        {loader && (
            <Container maxWidth="xs">
                <Box sx={{ width: "100%", display: 'flex', p:4, justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            </Container>
            )}
    </>);
}

export default Loader;
