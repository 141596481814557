import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container, Paper, Stack, List, ListItem, ListItemText, Button, Typography} from '@mui/material';
import {post, remove} from './api/all';
import DeleteIcon from '@mui/icons-material/Delete';
const Remove = () => {
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState('');
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            post(uid).then((data) => {
                setName(data.email);
            });
        }
    }, [loaded, uid]);

    const handleSubmit = (event) => {
        event.preventDefault();
        remove(uid).then((data) => {
            navigate("..", {state:{message:data}});
        });
    }

    return (<Container maxWidth="xs">
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack>
                <Typography variant="h5">¿Seguro que desea eliminar el siguiente registro?</Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Email" secondary={name} />
                    </ListItem>
                </List>
                <Stack direction="row" spacing={2}>
                    <Button onClick={() => navigate('..', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                        Cancelar
                    </Button>
                    <Button startIcon={<DeleteIcon />} type="submit" fullWidth variant="contained" color="error" sx={{ mt: 2, }}>
                        Eliminar
                    </Button>
                </Stack>
            </Stack>
        </Paper>
        </Container>);
}

export default Remove;
