import {endpoints} from './endpoints';

export const sendSignUp = (event, phone) => {
    const data = new FormData(event.currentTarget);

    const options = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({'email': data.get('email'), 'nombre': data.get('nombre'), 'apellido': data.get('apellido'), 'password': data.get('password'), 'phone': phone})
    }
    return fetch(endpoints.sign, options)
        .then((response) => response.json())
}

export const sendSignIn = (event) => {
    const data = new FormData(event.currentTarget);

    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'email': data.get('email'), 'password': data.get('password')})
    }
    return fetch(endpoints.sign, options)
        .then((response) => response.json());
};
