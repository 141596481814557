import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container, Paper, Stack, TextField, Button} from '@mui/material';
import {put} from './api/all';
import Message from './Message';
import CampaignSelect from './CampaignSelect';

const Add = () => {
    const [loaded, setLoaded] = useState(false);
    const [campaignUID, setCampaignUID] = useState('');
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event.currentTarget.files);
        put(event).then((data) => {
            if(data.status === 'success') {
                navigate("/promo/", {state:{message:data}});
            } else {
                navigate(".", {state:{message:data, uid: uid}});
            }
        });
    }


    return (<Container maxWidth="xs">
        <Message />
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>
                <CampaignSelect campaignUID={campaignUID} setCampaignUID={setCampaignUID} />
                <TextField
                name="name"
                required
                fullWidth
                id="name"
                label="Nombre de la Promoción"
                autoFocus />

                <TextField
                name="description"
                required
                fullWidth
                id="description"
                label="Descripción"
                autoFocus />

                <Stack direction="row" spacing={2}>
                    <Button onClick={() => navigate('/promo/', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                        Cancelar
                    </Button>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                        Guardar
                    </Button>
                </Stack>
            </Stack>
        </Paper>
        </Container>);
}

export default Add;
