import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container, Paper, Stack, TextField, Button} from '@mui/material';
import {post, patch} from './api/all';
import { MuiFileInput } from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
// date picker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './Edit.css';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import {TextareaAutosize} from './Textarea';

const Edit = () => {
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [expire, setExpire] = useState(dayjs());
    const [file, setFile] = useState(null);
    const [blob, setBlob] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            post(uid).then((data) => {
                setName(data.name);
                setExpire(dayjs(data.expire));
                setDescription(data.description);
                setCurrentFile(data.image);
            });
        }
    }, [loaded, name, uid]);

    const handleFileChange = (newValue) => {
        console.log(newValue);
        setFile(newValue);
        if(newValue !== null) {
            setBlob(URL.createObjectURL(newValue))
        } else {
            URL.revokeObjectURL(blob);
            setBlob(null);
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        patch(event, uid, file).then((data) => {
            navigate("..", {state:{message:data}});
        });
    }

    return (<Container maxWidth="xs">
        <Paper component="form" noValidate onSubmit={handleSubmit}>
            {blob === null && currentFile !== null && (<img src={currentFile} className="campaign-preview" alt="preview" />)}
            {blob !== null && (<img src={blob} alt="preview" className="campaign-preview"/>)}
            <Stack spacing={2} sx={{ p:2}}>

                <TextField
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                id="name"
                label="Nombre de la Campaña"
                autoFocus />

                <TextareaAutosize value={description} onChange={(e) => setDescription(e.target.value)} name="description" holder="Descripción" />

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker onChange={(newValue) => setExpire(newValue)} name="expire" label="Expiración" value={expire} defaultValue={dayjs()} />
                </LocalizationProvider>

                <MuiFileInput label="Nueva Imagen" value={file} onChange={handleFileChange} inputProps={{ accept: "image/png, image/gif, image/jpeg" }} 
                InputProps={{startAdornment: <AttachFileIcon /> }} clearIconButtonProps={{ title: "Quitar", children: <CloseIcon fontSize="small" /> }} />
            <Stack direction="row" spacing={2}>
                <Button onClick={() => navigate('..', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                    Cancelar
                </Button>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                    Guardar
                </Button>
            </Stack>
        </Stack>
    </Paper>
    </Container>);
}

export default Edit;
