import {Paper, List, ListItem, ListItemText, Stack, IconButton, Divider, Box} from
'@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from 'react-router-dom';
import './IndexItem.css';

const IndexItem = (props) => {
    const name = props.name;
    const promo = props.promo;
    const uid = props.uid;
    const qr_data = props.qr_data;
    const used = props.used;
    const campaign = props.campaign;

    const navigate = useNavigate();

    return (<Paper>
        <Stack direction="row">
            <List sx={{ width: "100%" }}>
                <ListItem>
                    <ListItemText primary="Nombre" secondary={name}></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Campaña" secondary={campaign}></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Promoción" secondary={promo}></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Utilizado" secondary={used?'Si':'Aún no'}></ListItemText>
                </ListItem>
            </List>

            <Box sx={{p:4}} align="center">
                <img src={qr_data} width="100" alt="QR" />
            </Box>
        </Stack>
        <Divider />
        <Stack direction="row" spacing={2} padding={2} display="flex" justifyContent="flex-end">
            <IconButton onClick={() => navigate('remove', {state: {uid: uid}})} aria-label="Borrar" size="large">
                <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => navigate('edit', {state: {uid: uid}})} aria-label="Editar" size="large">
                <EditIcon />
            </IconButton>
        </Stack>

        </Paper>);
}
export default IndexItem;
