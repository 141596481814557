import {Paper, List, ListItem, ListItemText, Stack, IconButton, Divider} from
'@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from 'react-router-dom';
import './IndexItem.css';

const IndexItem = (props) => {
    const uid = props.uid;
    const email = props.email;

    const navigate = useNavigate();

    return (<Paper>
        <Stack>
            <List sx={{ width: "100%" }}>
                <ListItem>
                    <ListItemText primary="Email" secondary={email}></ListItemText>
                </ListItem>
            </List>

        </Stack>
        <Divider />
        <Stack direction="row" spacing={2} padding={2} display="flex" justifyContent="flex-end">
            <IconButton onClick={() => navigate('remove', {state: {uid: uid}})} aria-label="Borrar" size="large">
                <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => navigate('edit', {state: {uid: uid}})} aria-label="Editar" size="large">
                <EditIcon />
            </IconButton>
        </Stack>

        </Paper>);
}
export default IndexItem;
