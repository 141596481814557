import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container, Paper, Stack, TextField, Button, List, ListItem, ListItemText} from '@mui/material';
import {post, patch} from './api/all';
// date picker

const Edit = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(undefined);
    const [name, setName] = useState('');
    const [promo, setPromo] = useState('');
    const [campaign, setCampaign] = useState('');
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            post(uid).then((data) => {
                setName(data.name);
                setPromo(data.promo)
                setCampaign(data.campaign);
                setData(data);
            });
        }
    }, [loaded, name, uid]);

    const handleSubmit = (event) => {
        event.preventDefault();
        patch(event, uid).then((data) => {
            navigate("..", {state:{message:data}});
        });
    }

    return (<Container maxWidth="xs">
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>

                <TextField
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                id="name"
                label="Nombre"
                autoFocus />

                <List>
                <ListItem>
                <ListItemText primary="Campaña" secondary={campaign} />
                </ListItem>
                <ListItem>
                <ListItemText primary="Promoción" secondary={promo} />
                </ListItem>
                </List>
                {data !== undefined && (
                <List>
                <ListItem>
                <ListItemText primary="Email" secondary={data.email} />
                </ListItem>
                <ListItem>
                <ListItemText primary="Phone" secondary={data.phone} />
                </ListItem>
                <ListItem>
                <ListItemText primary="Fecha de Nacimiento" secondary={data.born} />
                </ListItem>
                </List>
                )}

            <Stack direction="row" spacing={2}>
                <Button onClick={() => navigate('..', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                    Cancelar
                </Button>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                    Guardar
                </Button>
            </Stack>
        </Stack>
    </Paper>
    </Container>);
}

export default Edit;
