import {useEffect, useState} from 'react';
import {Container, Stack, Paper, Box} from '@mui/material';
import {get} from './api/all';
import IndexItem from './IndexItem';
//import IndexAdd from './IndexAdd';
import Message from './Message';
import Loader from './Loader';
import CustomPagination from './Pagination';

const Index = () => {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [pagination, setPagination] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            setLoader(true);
            get().then(data => {
                setItems(data.results);
                setPagination({'count': data.count, 'pages': data.pages, 'next': data.next, 'previous': data.previous});
                setLoader(false);
            });
        }
    }, [loaded, loader]);

    useEffect(() => {
        if(prevPage !== page) {
            setLoader(true);
            setPrevPage(page);
            get(page).then(data => {
                setItems(data.results);
                setPagination({'count': data.count, 'pages': data.pages, 'next': data.next, 'previous': data.previous});
                setLoader(false);
            });
        }

    }, [loaded, loader, page, prevPage]);
    return (<Container maxWidth="xs">
        <Message />
        <Loader loader={loader} />
        {loaded && !loader && items.length === 0 && (<Paper sx={{padding:2}}><Box align="center">No hay cupones para mostrar</Box></Paper>)}
        <Stack spacing={2}>
            {items.map((row, index) => (<IndexItem key={"item-"+index} uid={row.uid} name={row.name} promo={row.promo} qr_data={row.qr_data} used={row.used} campaign={row.campaign} />))}
            {pagination !== undefined && (<CustomPagination setItems={setItems} pages={pagination.pages} setPage={setPage} page={page} />)}
        </Stack>
        </Container>)
}
export default Index;
