import {Paper, List, ListItem, ListItemText, Stack, IconButton, Divider} from
'@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeIcon from '@mui/icons-material/QrCode';

import {useNavigate} from 'react-router-dom';
import './IndexItem.css';
import Share from './Share';

const IndexItem = (props) => {
    const name = props.name;
    const expire = props.expire;
    const uid = props.uid;
    const image = props.image;
    const campaign_url = props.campaign_url;

    const navigate = useNavigate();

    return (<Paper>
        <Stack>
            <img className="preview" src={image} alt="Preview" />
            <List sx={{ width: "100%" }}>
                <ListItem>
                    <ListItemText primary="Título" secondary={name}></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Expiración" secondary={expire}></ListItemText>
                </ListItem>
            </List>

        </Stack>
        <Divider />
        <Stack direction="row" spacing={2} padding={2} display="flex" justifyContent="flex-end">
            <Share data={campaign_url} />
            <IconButton onClick={() => navigate('qr', {state: {uid: uid}})} aria-label="Borrar" size="large">
                <QrCodeIcon />
            </IconButton>
            <IconButton onClick={() => navigate('remove', {state: {uid: uid}})} aria-label="Borrar" size="large">
                <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => navigate('edit', {state: {uid: uid}})} aria-label="Editar" size="large">
                <EditIcon />
            </IconButton>
        </Stack>

        </Paper>);
}
export default IndexItem;
