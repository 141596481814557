import {useEffect, useState} from 'react';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import {getAll} from '../Campaign/api/all';
import {useParams} from 'react-router-dom';
import Loader from './Loader';

const CampaignSelect = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [campaigns, setCampaigns] = useState(undefined);
    const {slug} = useParams();

    const setCampaignUID = props.setCampaignUID;
    const campaignUID = props.campaignUID;

    const handleChange = (event) => {
        setCampaignUID(event.target.value);
    };

    useEffect(() => {
        if(!loaded) {
            setLoader(true);
            setLoaded(true);
            setTimeout(() => {
                getAll().then((data) => {
                    setCampaigns(data);
                    setLoader(false)
                });
            }, 300);
        }
    }, [loaded, props, slug]);

    return (<>
        <Loader loader={loader} />
        {loaded && !loader && campaigns !== undefined && campaigns.length > 0 && (<FormControl fullWidth><InputLabel id="select-campaign-id">Campaña</InputLabel><Select id="select-campaign-uid" inputProps={{name:"campaign_uid"}} value={campaignUID} onChange={handleChange} label={"Campaña"}>
                <MenuItem value="">
                    <em>Sin asignar</em>
                </MenuItem>
                {campaigns.map((campaign, index) => <MenuItem key={"ss-"+index} value={campaign.uid}>{campaign.name}</MenuItem>)}
                </Select></FormControl>)}
    </>);

}

export default CampaignSelect;
