import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Container, Paper, Stack, TextField, Button} from '@mui/material';
import {post, patch} from './api/all';
import CampaignSelect from './CampaignSelect';

const Edit = () => {
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [campaignUID, setCampaignUID] = useState(undefined);
    const _location = useLocation();
    const uid = _location.state?.uid || undefined;
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            post(uid).then((data) => {
                setName(data.name);
                setDescription(data.description)
                setCampaignUID(data.campaign_uid);
            });
        }
    }, [loaded, name, uid]);

    const handleSubmit = (event) => {
        event.preventDefault();
        patch(event, uid).then((data) => {
            navigate("..", {state:{message:data}});
        });
    }

    return (<Container maxWidth="xs">
        <Paper component="form" noValidate onSubmit={handleSubmit} sx={{p:2}}>
            <Stack spacing={2}>

                <CampaignSelect campaignUID={campaignUID} setCampaignUID={setCampaignUID} />

                <TextField
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                id="name"
                label="Nombre de la Promoción"
                autoFocus />

                <TextField
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                fullWidth
                id="descripcion"
                label="Descripción"
                autoFocus />

            <Stack direction="row" spacing={2}>
                <Button onClick={() => navigate('..', {state: {message: {'message': 'Cancelado', status: 'info'}}})} fullWidth variant="contained" color="secondary" sx={{ mt: 2, }}>
                    Cancelar
                </Button>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, }}>
                    Guardar
                </Button>
            </Stack>
        </Stack>
    </Paper>
    </Container>);
}

export default Edit;
