import NotFound from './components/NotFound';
import Layout from './components/Layout';
import IconsLayout from './components/IconsLayout';

// auth
import Signup from './components/Auth/Signup/Signup';
import Signin from './components/Auth/Signin/Signin';
import Activate from './components/Auth/Activate/Activate';
import PasswordForgot from './components/Auth/PasswordForgot/PasswordForgot';
import PasswordForgotSent from './components/Auth/PasswordForgot/Sent';
import PasswordForgotChange from './components/Auth/PasswordForgot/Change';
import PasswordForgotModified from './components/Auth/PasswordForgot/Modified';

// campaign
import {default as CampaignIndex} from './components/Campaign/Index';
import {default as CampaignEdit} from './components/Campaign/Edit';
import {default as CampaignAdd} from './components/Campaign/Add';
import {default as CampaignRemove} from './components/Campaign/Remove';
import {default as CampaignQR} from './components/Campaign/QR';
//

// promo
import {default as PromoIndex} from './components/Promo/Index';
import {default as PromoEdit} from './components/Promo/Edit';
import {default as PromoAdd} from './components/Promo/Add';
import {default as PromoRemove} from './components/Promo/Remove';
//

// coupon
import {default as CouponIndex} from './components/Coupon/Index';
import {default as CouponEdit} from './components/Coupon/Edit';
import {default as CouponRemove} from './components/Coupon/Remove';
//

// accounts
import {default as AccountIndex} from './components/Account/Index';
import {default as AccountAdd} from './components/Account/Add';
import {default as AccountEdit} from './components/Account/Edit';
import {default as AccountRemove} from './components/Account/Remove';
//

import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";

const router = createBrowserRouter([
    {   
        path: "/account",
        element: <IconsLayout />,
        children: [
            {
                path: "add",
                element: <AccountAdd />,
            },
            {
                path: "edit",
                element: <AccountEdit />,
            },
            {
                path: "remove",
                element: <AccountRemove />,
            },
            {
                path: "",
                element: <AccountIndex />,
            },
        ]
    },
    {   
        path: "/coupon",
        element: <IconsLayout />,
        children: [
            {
                path: "edit",
                element: <CouponEdit />,
            },
            {
                path: "remove",
                element: <CouponRemove />,
            },
            {
                path: "",
                element: <CouponIndex />,
            },
        ]
    },
    {   
        path: "/promo",
        element: <IconsLayout />,
        children: [
            {
                path: "edit",
                element: <PromoEdit />,
            },
            {
                path: "add",
                element: <PromoAdd />,
            },
            {
                path: "remove",
                element: <PromoRemove />,
            },
            {
                path: "",
                element: <PromoIndex />,
            },
        ]
    },
    {   
        path: "/campaign",
        element: <IconsLayout />,
        children: [
            {
                path: "edit",
                element: <CampaignEdit />,
            },
            {
                path: "add",
                element: <CampaignAdd />,
            },
            {
                path: "qr",
                element: <CampaignQR />,
            },
            {
                path: "remove",
                element: <CampaignRemove />,
            },
            {
                path: "",
                element: <CampaignIndex />,
            },
        ]
    },
    {   
        path: "",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Navigate to="/auth/sign-in/" replace />
                },
        ]
    },
    {   
        path: "/auth",
        element: <Layout />,
        children: [
            {
                path: "activate/email/:uid",
                element: <Activate />
                },
            {
                path: "password-forgot/change/:uid",
                element: <PasswordForgotChange />
                },
            {
                path: "password-forgot/modified",
                element: <PasswordForgotModified />
                },
            {
                path: "password-forgot/",
                element: <PasswordForgot />
                },
            {
                path: "password-forgot/sent",
                element: <PasswordForgotSent />
                },
            {
                path: "sign-in",
                element: <Signin />
                },
            {
                path: "sign-up",
                element: <Signup />
                },
        ],
    },
    {
        path: "*",
        element: <NotFound />
        }
]);

const App = () => {
    return (
        <RouterProvider router={router} />
        );
}

export default App;
